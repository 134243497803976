const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://gqnk1xtjl1.execute-api.eu-west-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://djl8oh5xy2.execute-api.eu-west-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.staging.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://i1hb5vtuq9.execute-api.eu-west-1.amazonaws.com/staging'
  },
};

export default config;
